@import "Vars.less";

.content h3 {
    background-color: transparent !important;
}
.v-btn{
    /*padding:0 !important;*/
}
.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}