@import "Vars.less";


#ct_palettes{
    padding: 120px 0 0 0;
    //height:100%;

    .nano{
        position:absolute;
        margin:20px 0 0 0;
        height:calc(~"100% - 240px");
        width:calc(~"100% - 20px");
    }

    .StockBlock{
        margin:0 0 30px 0;
    }

    table{
        margin:0;
        -webkit-border-horizontal-spacing: 0;
        -webkit-border-vertical-spacing: 0;
        padding:0 16px 0 0;

        tr{
            margin:0;
            padding:0;
            cursor:pointer;

            &.Selected{
                background-color:@verallia_green_alpha;
            }
        }
        tr:first-child{
            cursor: default;

            th{
                border-top:none;
            }
        }
        th{
            text-align:left;
            margin:0;
            padding:0;
            border-top:1px solid @light_grey;
            font-size:12px;


            span{
                display:table-cell;
                vertical-align: middle;
                text-align:left;
                font-weight: normal;
                padding:0 20px 0 5px;
                height:40px;

                img{
                    display: inline-block;
                    vertical-align: middle;
                    margin:0 5px 0 0;
                }
            }
        }
    }

    .CartPlanIcon{
        color:@verallia_green;
    }

    .CTStockIcon{
        display:inline-block !important;
        vertical-align: top;
        margin:0 0 0 10px;
    }
    .CTStockGreenIcon{
        color:@verallia_green;
    }
    .CTStockRedIcon{
        color:@red;
    }

    .StockAllCountries{
        margin:0 10px 0 0;
        padding:0 0 10px 0;
        height:26px !important;

        .v-icon{
            margin-left:10px;
        }

        .v-btn__content{
            margin:0;
            padding:0;
        }

        &.Selected{
            -webkit-box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
            -moz-box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
            box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
        }
    }
    .StockCountryFlag{
        margin:0 10px 0 0;
        cursor:pointer;
        display:inline-block;
        vertical-align: top;

        img{
            height:26px;
            width:auto;
            font-size: 0;
            line-height: 0;
            display:block;
        }

        &.Selected{
            -webkit-box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
            -moz-box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
            box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
        }
    }

}
#ct_palettes_table{
    .PurpleStar{
        font-size:19px;
        color:@verallia_purple;
        margin-left:5px;
    }
}
.CTStockIcon{
    display:inline-block !important;
    vertical-align: top;
    margin:0 0 0 10px;
}
.CTStockGreenIcon{
    color:@verallia_green;
}
.CTStockRedIcon{
    color:@red;
}

.StockAllCountries{
    margin:0 10px 0 0;
    padding:0 0 10px 0;
    height:26px !important;

    .v-icon{
        margin-left:10px;
    }

    .v-btn__content{
        margin:0;
        padding:0;
    }

    &.Selected{
        -webkit-box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
        -moz-box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
        box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
    }
}
.StockCountryFlag{
    margin:0 10px 0 0;
    cursor:pointer;
    display:inline-block;
    vertical-align: top;

    img{
        height:26px;
        width:auto;
        font-size: 0;
        line-height: 0;
        display:block;
    }

    &.Selected{
        -webkit-box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
        -moz-box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
        box-shadow: 0px 0px 10px 1px rgba(0,168,167,1);
    }
}
#cart_container{
    position:absolute;
    width:240px;
    height:130px;
    border:2px solid @verallia_green;
    background-color:@white_alpha;
    padding:20px;
    //z-index:10 !important;

    input{
        position: relative;
        display: block;
        border: 1px solid @light_grey3;
        padding: 10px;
        text-align: center;
        width: 60px;
        font-size: 20px;
    }

    .CloseCartButton {
        position: absolute;
        right: 0;
        top: 0;
        color: @light_grey2;
    }

    .CartQuantityIcon{
        border-radius:50%;
        width:20px;
        height:20px;
        background-color: @verallia_green;
        color: @white;
        font-size:12px;
        margin-left:10px;
        cursor:pointer;
    }

    #add_to_cart{
        color:@verallia_green;
        margin:10px 0 0 0;
        padding-left:0;
        padding-right:0;

        .v-btn__content{
            padding-left:0;
        }

        .v-icon{
            color:@verallia_green;
            font-size:36px;
        }
    }

    &.ArrowLeft:after, &.ArrowLeft:before {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &.ArrowLeft:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #ffffff;
        border-width: 20px;
        margin-top: -20px;
    }
    &.ArrowLeft:before {
        border-color: rgba(0, 167, 167, 0);
        border-right-color: #00a7a7;
        border-width: 23px;
        margin-top: -23px;
    }

    &.ArrowBottom:after, &.ArrowBottom:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &.ArrowBottom:after {
        border-color: rgba(0, 167, 167, 0);
        border-top-color: #ffffff;
        border-width: 20px;
        margin-left: -20px;
    }
    &.ArrowBottom:before {
        border-color: rgba(0, 167, 167, 0);
        border-top-color: #00a7a7;
        border-width: 23px;
        margin-left: -23px;
    }


}


.isMobile{
    #ct_palettes{
        margin-top:30px;
        margin-bottom:50px;
        padding-top:0;
    }
    .CartQuantityIcon {
        width: 30px;
        height: 30px;
        background-color: @verallia_green;
        color: @white;
        font-size: 18px;
        margin-left: 10px;
    }
    #ct_palettes_table{
        width:calc(~"100% - 20px");
    }
    #cart_container{
        position:absolute;
        width:calc(~"100% - 20px");
        height:100%;
        min-height:140px;
        border:none;

        .CloseCart{
            width:calc(~"100% - 20px");
            text-align: right;
            color:@verallia_green;
            position:absolute;
            top:-40px;
            right:20px;
            font-weight: bold;
            font-size:14px;
            border:2px solid @verallia_green;
            cursor:pointer;

            .v-icon{
                color:@verallia_green;
            }
        }
    }
}