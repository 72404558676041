@import "Vars.less";

.application.theme--light{
    background:#ffffff !important;
    color:#000000 !important;
}
.application.theme--light{
    background:#ffffff !important;
}
.mdi{
    font-size: 30px !important;
    color:inherit !important;
}
.snotifyToast__buttons button{
    margin-bottom:0 !important;
}
.snotify-confirm{
    background-color:@verallia_green !important;
}
.v-btn{
    text-transform: none !important;
}
.primary--text {
    color: @verallia_green!important;
}
.list__tile--active{
    color: @verallia_green !important;
}
.menu__content--select .text--disabled{
    display:none !important;
}
[type=reset], [type=submit], button, html [type=button]{
    -webkit-appearance:inherit !important;
}
.v-btn{
    padding:0 8px !important;
}