@import "Vars.less";

.ICT{
    /* FILTERS */



    .CountryFilterElementSelected.Selected{
        border:2px solid @verallia_green;
    }
    .CountryFilterElementSelected span{
        border-color: transparent @verallia_green transparent transparent !important;
    }
    .accent--text{
        color:@verallia_green !important;
        margin:0;
    }
    .CTFilterCheckbox.v-input{
        margin-top:0;

        .v-messages{
            display:none;
        }
    }
    .CTFilterValuesInput.v-input{
        width:40%;
        display:inline-block;
        margin-right:5%;

        .v-messages{
            display:none;
        }
    }




    .CTFilterCheckbox .input-group__details, .CTFilterValuesInput .input-group__details{
        display:none;
    }
    .CTFilterValuesInput.input-group.input-group--selection-controls{
        padding: 0 0 10px 0;
    }
    .CTFilterValuesInput.input-group--text-field input{
        background:@white;
        border:1px solid @light_grey2;
        padding:3px;
    }



    /* PRODUCTS */


    #ct_products_list_container{
        background-color:@white;
    }
    .CTProductCard:hover .CTProductCardOverlay{
        border:3px solid @light_grey3;
    }
}