@import "Vars.less";

#ct_products{
    vertical-align: top;
    display:inline-block;
    width:calc(~"100% - 300px");
    height:calc(~"100% - 173px");

    width:100%;
    margin-top:93px;
    padding:0;
    position:absolute;
}
#ct_products_overlay{
    position:absolute;
    z-index: 3;
    background-color:rgba(255, 255, 255, 0.9);
    width:100%;
    height:1000%;
}
#ct_products_header{
    display:none;
    width:100%;
    height:40px;
    border-bottom:1px solid @light_grey;
}
.CTProductHeaderBlock{
    display:inline-block;
    vertical-align: top;
}
#ct_products_list{
    width:100%;

    .nano-pane{
        z-index: 3 !important;
        margin:0 0 0 0 !important;
        width:15px;
    }
}
#ct_products_list .nano-content{

}
#ct_products_list_container{
    width:calc(~"100% - 25px");
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content:flex-start;
    padding:0;
}
.CTProductCard{
    position:relative;
    height:390px;
    padding:0;

    display: flex;
    min-width: 350px;
    max-width: 700px;
    flex-grow: 1;

    background: url("../../assets/images/interface/product_gradient.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .CTProductCardSelectBottle{
        width:60px !important;
        height:60px !important;

        .v-icon{
            font-size:30px !important;
        }
    }
}
.CTPhantomProductCard{
    opacity:0;
}
.CTProductCardOverlay{
    z-index:1;
    width:100%;
    height:100%;
    position:absolute;
    box-sizing: border-box;
    border:1px solid transparent;
    cursor:pointer;
}
.CTProductCard:hover .CTProductCardOverlay{
    /*border:3px solid transparent;*/
    /*z-index:1000;*/

    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
}
.CTProductCardContainer{
    position:relative;
    width:calc(~"100% - 30px");
    height:calc(~"100% - 30px");
    margin:15px 0 0 15px;
}
.CTProductCardPLS{
    .CTProductCardTitleContainer{
        height:180px;
    }
    .CTProductCardImage{
        max-height:190px;
        bottom:140px;
    }
}
.CTProductCardTitleContainer{
    top:40px;
    width:calc(~"100% - 160px");
    height:220px;
    position:absolute;
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.CTPremiumCard{
    position:absolute;
    opacity: 0.8;
    margin-top:20px;

    img{
        display:block;
        width:40px;
        height:auto;
    }
}
.CTProductCardTitle{
    h3{
        font-family:"PoppinsBold";
        font-size:20px;
        letter-spacing: 1px;
        line-height:20px;
        text-transform: uppercase;
        color:@light_grey2;
        padding:0;
        margin:0;
        word-wrap: break-word;
        text-align: right;
        width:100%;
    }

    margin: 0 0 5px 0;
    width:100%;
    display:flex;
    align-self: flex-end;
}
.CTProductCardLogos{
    .CTProductCardLogo{
        display:block;
        width:100%;

        img{
            height:30px;
            width:auto;
            margin: 0 0 0 5px;
            display:inline-block;
        }
    }


    text-align: right;
    width:100%;
    display:flex;
    align-self: flex-end;
}
.CTProductCardLine{
    span{
        width:40px;
        height:2px;
        background-color:@verallia_green;
        margin-left:calc(~"100% - 40px");
    }

    margin:15px 0 0;
    width:100%;
    display:flex;
    align-self: flex-end;
}
.CTProductCardOrderIcon{
    position:absolute;
    width:auto;
    right:15px;
    bottom:100px;
    height:36px;

    span{
        position:absolute;
        font-size:14px;
        color:@verallia_green;
        margin:-20px 0 0 0;
    }

    img{
        height:36px !important;
        width:auto !important;
    }
}
.CTProductCardImage{
    position:absolute;
    width:auto;
    max-height:230px;
    max-width:90px;
    display:block;
    right:60px;
    bottom:100px;
}
.CTProductCardInfosContainer{
    position:absolute;
    width:100%;
    bottom:20px;
}
.CTProductCardInfosBlock{
    display:inline-block;
    width:32%;
    vertical-align: top;
    text-align:right;
    position:relative;

    img{
        height:20px;
        width:auto;
        position:absolute;
        display:block;
        top:5px;
    }

    .CTProductCardInfosBlock{
        width:100%;
        position:absolute;
        left:25px;
        text-align: left;

        font-family:"PoppinsLight";
        font-size:14px;
        letter-spacing: 1px;
        line-height:14px;
        text-transform: uppercase;
        color:@light_grey2;
    }
    .CTProductCardInfosBlockBottom{
        position:absolute;
        left:25px;
        top:20px;
        text-align: left;

        font-family:"PoppinsLight";
        font-size:10px;
        letter-spacing: 1px;
        line-height:10px;
        text-transform: uppercase;
        color:@verallia_green;
    }
}
.CTProductCardInfosContainerPLS{
    position:absolute;
    width:100%;
    bottom:0;
}
.CTProductCardInfosBlockPLS{
    display:block;

    .v-icon{
        font-size:14px;
    }

    .CTProductCardInfosBlockPLSText{
        display:inline-block;
        font-family:"PoppinsLight";
        font-size:12px;
        letter-spacing: 1px;
        line-height:12px;
        text-transform: uppercase;
        color:@light_grey2;
    }
}
.CTProductCardCountries{
    position:absolute;
    top:20px;
    right:10px;
    width:30px;
}
.CTProductCardCountries img{
    height:12px;
    width:auto;
    display:block;
    margin:0 5px 10px 0;
}
.CTProductCardInfos{
    position:absolute;
    padding:5px 0 5px 0;
    border-bottom:1px solid @light_grey;
    width:100px;
}
.CTProductCardInfosPLS{
    width:100% !important;
    border-bottom:none !important;
}
.CTProductCardInfos span{
    display:block;
    width:100%;
}
.CTProductCardInfosPLS span{
    margin:0 0 5px 0;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.CTSmallLabel{
    margin: 0;
    padding-top: 5px;
}
.CTProductCardInfosPLS .v-icon{
    display:inline-block;
    vertical-align: top;
}
.CTProductCardColors{
    z-index:2;
    position:absolute;
    right:15px;
    bottom:60px;
    width:100%;
    display:flex;
    justify-content: flex-end;

    .CTProductCardColor{
        display:flex;
        margin:0 10px 0 0;
    }

    .v-icon{
        color:@verallia_purple !important;
        position:absolute;
        margin:15px 0 0 0;
        font-size:15px;
    }
}
.CTProductCardColors .CTProductCardColorCircle{
    width:15px;
    height:15px;
    border-radius:7.5px;
    border:1px solid @light_grey3;
    display:flex;
    margin:0 0 5px 0;
    cursor:pointer;
    z-index:1;

    &.PremiumColor{
        border:2px solid @verallia_purple;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(174,0,121,1);
        -moz-box-shadow: 0px 0px 8px 0px rgba(174,0,121,1);
        box-shadow: 0px 0px 8px 0px rgba(174,0,121,1);

        .ExtraWhiteE{
            margin:-4px 0 0 2px;
        }
    }

    .ExtraWhiteE{
        color:@black;
        opacity:0.8;
        font-size:12px;
        margin:-3px 0 0 3px;
    }
}
.CTProductCardColors .CTProductCardColorCircle:hover{
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}
.CTProductLogoSmall{
    height:25px !important;
}
.CTProductCardActions{
    position:absolute;
    top:10px;
    width:calc(~"100% - 120px");
}
.CTProductCardActionButton{
    border:1px solid @verallia_green;
    margin:0 20px 0 0;
    display:inline-block;
    z-index: 2;
    color:@verallia_green;

    .v-btn__content{
        color:@verallia_green;

        font-family:"PoppinsBold";
        font-size:12px;
        letter-spacing: 1px;
        line-height:12px;
        text-transform: uppercase;
        color:@verallia_green;
    }
}
.CTProductCardActionButtonAdded{
    border:1px solid @white;
    color:@white;
    background-color:@verallia_green !important;

    .v-btn__content{
        color:@white;
    }
}
.CTProductCardActions .v-btn--small{
    margin:0;
    font-size:12px;
}
.CTProductCardActions .v-btn--small .v-icon{
    font-size:14px;
}
.CTProductCardActions .v-btn--small.v-btn__content{

}