@import "Vars.less";

#ct_filters{
    vertical-align: top;
    display:inline-block;
    /*width:300px;*/
    margin:0 0 0 365px;
    width:calc(~"100% - 365px") !important;

}
.CTHeaderAddBlock{
    font-size:11px;
    cursor:pointer;
    display:inline-block;
    vertical-align: top;
    margin:0 30px 0 0;

    h3{
        font-family:"PoppinsRegular";
        font-size:10px;
        font-weight: normal;
        letter-spacing: 1px;
        color:@light_grey2;

        margin:8px 15px 0 0;
        padding:0;
        line-height:10px;
        display:inline-block;
        vertical-align: top;
        text-transform: uppercase;
        font-weight: normal;
    }

    .v-icon{
        margin:0;
        display:inline-block;
        vertical-align: top;
        color:@verallia_green !important;
    }
}
.CTHeaderResetFiltersBlock{
    font-size:11px;
    cursor:pointer;
    display:inline-block;
    vertical-align: top;
    margin:0 0 0 20px;

    h3{
        font-family:"PoppinsRegular";
        font-size:10px;
        font-weight: normal;
        letter-spacing: 1px;
        color:@light_grey2;

        margin:8px 15px 0 0;
        padding:0;
        line-height:10px;
        display:inline-block;
        vertical-align: top;
        text-transform: uppercase;
        font-weight: normal;
    }

    .v-icon{
        margin:0;
        display:inline-block;
        vertical-align: top;
        font-size:16px;
        margin:4px 0 0 0;
    }
}
.CTHeaderBlocksContainer{
    display:inline-block;
    vertical-align: top;
    width:calc(~"100% - 350px");
}
.CTHeaderFilterBlock{
    display:inline-block;
    vertical-align: top;
    margin:0 10px 5px 0;
    background-color:@white;
    border-radius:15px;
    padding:5px 10px;
    font-family:"PoppinsRegular";
    font-size:10px;
    letter-spacing: 1px;
    color:@light_grey2;
    cursor:pointer;

    h3{
        font-family:"PoppinsRegular";
        font-size:10px;
        letter-spacing: 1px;
        color:@light_grey2;
        font-weight: normal;

        margin:0;
        padding:0;
        line-height:10px;
        display:inline-block;
        text-transform: uppercase;
    }

    &.Alpha{
        opacity:0.3;
    }
}
.CTHeaderFilterBlockPreview{
    display:inline-block;
    vertical-align: top;
    margin:0 0 0 5px;
}
.CountryFilterElementPreview{
    display:inline-block;
    margin:0 5px 0 0;
    width:20px;
    height:auto;
}
.CTFilterColorPreview{
    width:10px;
    height:10px;
    border-radius:50%;
    border:1px solid @light_grey;
    display:inline-block;
    margin:0 5px 0 0;
    vertical-align: top;
}



#ct_filter_block{
    display:none;
    position:fixed;
    z-index: 2;
    background-color:@white;
    border-radius:20px;
    padding:30px;

    .nano{
        max-height:400px;
    }

    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5);

    .CTFilterTriangle{
        position:absolute;
        margin-top:-40px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 10px 7px;
        border-color: transparent transparent @white transparent;
    }

    &.GreenBlock{
        background-color:@verallia_green;
        padding-bottom:5px;

        .CTFilterTriangle{
            border-color: transparent transparent @verallia_green transparent;
        }
    }
}
#ct_filter_block_close{
    cursor:pointer;
    position:absolute;
    font-family:"PoppinsRegular";
    font-size:12px;
    letter-spacing: 1px;
    line-height:12px;
    text-transform: uppercase;
    color:@white;

    background-color:@verallia_green;
    border-radius:30px;
    padding:15px 20px 15px 20px;

    left:0;
    bottom:-70px;

    .v-icon{
        color:@white;
        font-size:16px;
    }
}
#ct_filter_block_remove{
    cursor:pointer;
    position:absolute;
    font-family:"PoppinsRegular";
    font-size:12px;
    letter-spacing: 1px;
    line-height:12px;
    text-transform: uppercase;
    color:@white;

    background-color:@verallia_green;
    border-radius:30px;
    padding:15px 20px 15px 20px;

    right:0;
    bottom:-70px;

    .v-icon{
        color:@white;
        font-size:16px;
    }
}
.CTFilterSelectorBlock{
    position:relative;
    background-color:@white;
    color:@light_grey2;
    border-radius:20px;
    display:inline-block;
    margin:0 15px 25px 0;

    font-family:"PoppinsRegular";
    font-size:12px;
    letter-spacing: 1px;
    line-height:12px;
    text-transform: uppercase;

    cursor:pointer;

    .FilterSelectorOverlay{
        position:absolute;
        display:none;
        background-color:@verallia_green_alpha2;
        width:100%;
        height:100%;
        border-radius:20px;
        z-index:3;

        .v-icon{
            color:@white;
            font-size:14px;
            text-align: center;
            display:block;
            margin:8px 0 0 0;
        }
    }
    .FilterSelectorContent{
        padding:10px 15px 10px 15px;
    }

    &.Selected{
        .FilterSelectorOverlay{
            display:block;
        }
    }
}



/* COUNTRIES */

.CountryFilterElement{

}
.CountryFilterElementContainer{
    width:100%;
    height:40px;
    margin:0 0 10px 0;
    position:relative;
    cursor:pointer;
}
.CountryFilterElement img{
    height:40px;
    width:auto;
}
.CountryFilterElementSelected.Selected{
    position:absolute;
    z-index: 1;
    display:block;
    width:67px;
    height:40px;
    border:2px solid @verallia_green;
}
.CountryFilterElementSelected{
    display:none;
}
.CountryFilterElementSelected span{
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 28px 28px 0;
    border-color: transparent @verallia_green transparent transparent;
    margin-left: 36px;
}
.CountryFilterElementSelected .v-icon{
    color: @white !important;
    font-size: 15px;
    margin-left: 13px;
    margin-top: -10px;
}


/* RANGES */
.SubFilters{
    padding-left:30px;
}


/* COLORS */
.CTColorCheckBox label{
    padding-left:30px;
}
.CTColorCheckBoxColorPreview{
    position:absolute;
    display:inline-block;
    width:15px;
    height:15px;
    border-radius:7.5px;
    border:1px solid @light_grey3;
    margin:7px 0 0 35px;
}
.CTFilterValuesInput.v-input-group.v-input-group--text-field{
    width:45%;
    display:inline-block;
}


.isMobile{
    #ct_filters{
        margin:10px 0 0 20px !important;
        width:calc(~"100% - 20px") !important;
    }
    #ct_filters_list{
        margin-top:30px;
    }
    .CTFilterColorPreview {
        width: 14px;
        height: 14px;
    }
    .CTHeaderBlocksContainer {
        width: calc(~"100% - 40px");
    }
}
@media all and (min-width: 0) and (max-width: 1250px) {
    #ct_filters{
        vertical-align: top;
        display:inline-block;
        margin:50px 0 0 40px !important;
        width:calc(~"100% - 40px") !important;
    }
}
@media all and (min-width: 0) and (max-width: 950px) {
    .CTHeaderBlocksContainer{
        position:absolute;
        display:block;
        width:calc(~"100% - 80px");
        margin:20px 0 0 0;
    }
    .CTHeaderResetFiltersBlock{
        position:absolute;
        right:30px;
    }
}