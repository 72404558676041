@import "Vars.less";

#ct_product_details > div.nano.has-scrollbar > div.nano-pane{
    visibility:hidden;
}

#ct_product{
    position:relative;
    vertical-align: top;
    display:inline-block;
    width:100%;
    margin-top:35px;



    .OrderButton {
        background-color: @white !important;
        position: relative;
        display: flex;
        font-family: "PoppinsLight";
        font-size: 12px;
        margin-top: 15px !important;

        color: @verallia_green;

        .v-icon {
            color: @verallia_green  !important;
        }
    }

    .CTProductHeader{
        height:93px;
        background-color:@white;
        padding:35px 0 0 40px;
        position:fixed;
        //top:35px;
        width:790px;
        z-index: 2;


        .v-btn{
            margin:0 15px 0 0;
            .v-icon{
                color:@light_grey2;
            }
        }




        h1{
            font-family: "PoppinsLight";
            color: @verallia_green;
            margin:0;
            padding:0;
            font-size:18px;
            font-weight: normal;
            display:inline-block;
            vertical-align: top;
        }
    }
}
#ct_product_details{
    position:absolute;
    width:790px;
    height:calc(~"100% - 35px");
    //min-height:900px;
    //max-height:1080px;
    display:inline-block;
    vertical-align: top;
    //padding-top: 93px;

    .CTProductTitleContainer{
        margin:30px 0 0 0;
        width:calc(~"100% - 230px");
        position:absolute;
        display:flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .CTProductTitle{
        h3{
            font-family:"PoppinsBold";
            font-size:38px;
            letter-spacing: 4px;
            line-height:45px;
            text-transform: uppercase;
            color:@light_grey2;
            padding:0;
            margin:0;
            word-wrap: break-word;
            text-align: right;
            width:100%;
            z-index: 1;
        }

        margin: 0 0 10px 0;
        width:100%;
        display:flex;
        align-self: flex-end;
    }
    .CTProductLogos{
        .CTProductLogo{
            display:block;
            width:100%;

            img{
                height:50px;
                width:auto;
                margin:0 0 0 15px;
                display:inline-block;
            }
        }


        text-align: right;
        width:100%;
        display:flex;
        align-self: flex-end;
    }
    .CTProductLine{
        span{
            width:40px;
            height:2px;
            background-color:@verallia_green;
            margin-left:calc(~"100% - 40px");
        }

        margin:15px 0 0;
        width:100%;
        display:flex;
        align-self: flex-end;
    }
    .CTProductActions{
        margin:15px 0 0;
        width:100%;
        display:flex;
        align-self: flex-end;
        padding:0 0 0 40px;
        z-index: 1;
    }
    .CTProductCardSelectBottle{
        width:100px !important;
        height:100px !important;

        .v-icon{
            font-size:45px !important;
        }
    }
    .CTProductActionButton{
        border:1px solid @verallia_green;
        margin:0 20px 0 0;
        display:inline-block;
        vertical-align: top;

        color:@verallia_green;

        .v-btn__content{
            color:@verallia_green;

            font-family:"PoppinsBold";
            font-size:12px;
            letter-spacing: 1px;
            line-height:12px;
            text-transform: uppercase;
            color:@verallia_green;
        }
    }
    .CTProductColors{
        z-index:2;
        //position:absolute;
        padding-right:240px;
        width:100%;
    }
    .CTProductColorsContainer{
        .CTProductColor{
            display:flex;
            margin:0 0 0 10px;
        }

        .CTProductColorSelected{
            color:@verallia_green !important;
            position:absolute;
            margin: -20px 0 0 12px;
            font-size:20px;
        }

        .CTProductColorFavorite{
            color:@verallia_purple !important;
            position:absolute;
            margin:25px 0 0 10px;
            font-size:20px;
        }


        display:flex;
        justify-content: flex-end;
    }
    .CTProductColorsContainer .CTProductColorCircle{
        width:24px;
        height:24px;
        border-radius:12px;
        border:1px solid @light_grey3;
        display:flex;
        margin:0 0 0 10px;
        cursor:pointer;
        z-index:1;

        &.PremiumColor{
            border:2px solid @verallia_purple;
            -webkit-box-shadow: 0px 0px 12px 0px rgba(174,0,121,1);
            -moz-box-shadow: 0px 0px 12px 0px rgba(174,0,121,1);
            box-shadow: 0px 0px 12px 0px rgba(174,0,121,1);

            .ExtraWhiteE{
                margin:-6px 0 0 5px;
            }
        }

        .ExtraWhiteE{
            color:@black;
            opacity:0.5;
            font-size:20px;
            margin:-5px 0 0 5px;
        }
    }
    .CTProductColors .CTProductColorCircle:hover{
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    }
}
.ProductInfosContent{
    height:790px;
    display:flex;
    flex-wrap: wrap;
    margin-top:95px;
}
.CTPremiumSheet{
    position:relative;
    opacity: 0.8;
    margin-top:50px;

    img{
        display:block;
        width:50px;
        height:auto;
    }
}
#ct_product_selector_color_name{
    display:block;
    width:100%;
    text-align: right;
    margin:15px 0 0 0;

    font-family:"PoppinsLight";
    font-size:12px;
    letter-spacing: 1px;
    line-height:12px;
    text-transform: uppercase;
}
#ct_product_image{
    /*position:absolute;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width:calc(~"100% - 175px");
    height:calc(~"100% - 340px");
    bottom:175px;*/

    //position: absolute;
    display: flex;
    align-self: flex-end;
    justify-content: center;
    width: calc(100% - 175px);
    height: calc(100% - 175px);

    img{
        display:flex;
        align-self:flex-end;
        width:auto;
        max-height:400px;
        max-width:400px;
        /*height:100%;*/
    }
}
.CTProductCountries{
    margin:20px 0 0 0;
    align-self: flex-end;
    display: flex;
}
.CTProductCountries img{
    height:20px;
    width:auto;
    display:inline-block;
    margin:0 0 10px 10px;
}
#ct_product_infos{
    position:absolute;
    width:175px;
    right:0;
    height:200px;
    margin:35px 0 0 0;


    .CTProductInfosBlockContainer{
        display:block;
        width:100%;
        height:65px;
        text-align:left;
        position:relative;

        img{
            height:20px;
            width:auto;
            position:absolute;
            display:block;
            top:5px;
        }
        .v-icon{
            position:absolute;
        }
        .CTProductInfosBlock{
            position:absolute;
            left:30px;
            text-align: left;

            font-family:"PoppinsLight";
            font-size:14px;
            letter-spacing: 1px;
            line-height:14px;
            text-transform: uppercase;
            color:@light_grey2;

            span{
                display:block;
                font-family:"PoppinsLight";
                font-size:10px;
                letter-spacing: 1px;
                line-height:10px;
                text-transform: uppercase;
                color:@verallia_green;
                margin:4px 0 0 0;
            }
        }
        .CTProductInfosBlockBottom{
            position:absolute;
            left:30px;
            top:20px;
            text-align: left;

            font-family:"PoppinsLight";
            font-size:10px;
            letter-spacing: 1px;
            line-height:10px;
            text-transform: uppercase;
            color:@verallia_green;
        }
        .CTProductInfosBlockBottom2{
            position:absolute;
            left:40px;
            top:10px;
            text-align: left;

            font-family:"PoppinsLight";
            font-size:10px;
            letter-spacing: 1px;
            line-height:10px;
            text-transform: uppercase;
            color:@verallia_green;
        }
        .MTOData{
            display:block;
            color:@light_grey2 !important;
            position:absolute;
            margin-top:60px;
            margin-bottom:10px;
            font-size:12px;
            letter-spacing: 1px;
            line-height:14px;
            width:400px;

            span{
                font-family:"PoppinsLight";
                font-size:12px;
                margin-top:5px;
                display:block;
                margin-top:2px;
            }
        }
    }

    .CTProductInfosBlockContainerSmall{
        height:55px !important;
    }

    .StockIndicator{
        position:absolute;
        right:35px;
        display:inline-block;
        width:20px;
        height:20px;
        border-radius:10px;
        border:1px solid @light_grey2;

        &.StockOrangeIndicator{
            background-color:@orange;
        }
        &.StockGreenIndicator{
            background-color:@green;
        }
    }
}
#ct_product_description{
    display:flex;
    align-items: center;
    justify-content: center;
    height:calc(~"100% - 250px");

}
.CTProductDescription{
    font-family:"PoppinsLight";
    font-size:22px;
    letter-spacing: 1px;
    line-height:28px;
    letter-spacing:1px;
    word-spacing:5px;
    width:50%;
    margin-top:400px;
    margin-top:640px;
}
.CTProductDescriptionLine{
    width: 40px;
    height: 2px;
    background-color: #00a8a7;
    display: block;
    margin: 20px 0 0 0;
}
#ct_product_docs{
    /*position:absolute;
    width:100%;
    bottom: 80px;
    text-align: left;
    padding: 0 0 0 20px;*/

    display: flex;
    align-self: flex-end;
    width: 100%;
    margin-bottom: 40px;
    text-align: left;
    padding: 0 0 0 20px;

    .v-btn{
        height:42px;
        border:1px solid @verallia_green;
        padding:0 20px 0 15px !important;

        .v-btn__content{
            color:@verallia_green;
            font-family:"PoppinsLight";
            font-size:12px;
            letter-spacing: 1px;
            line-height:12px;
            text-transform: uppercase;
        }

        .v-icon{
            color:@verallia_green;
        }
    }
}




#ct_product_right_block{
    position:absolute;
    width:calc(~"100% - 790px");
    height:calc(~"100% - 35px");
    margin-left:790px;
    //min-height:900px;
    //max-height:1080px;
    display:inline-block;
    vertical-align: top;
    padding-top: 0;

    iframe{
        width:100%;
        height:calc(~"100% - 75px");
        margin-top:75px;
    }
}
#ct_product_right_block_top_menu{
    position:fixed;
    width:calc(~"100% - 790px");
    height:50px;
}
.CTProductOrderIcon{
    margin-top:5px;
    width:auto !important;
    height:36px !important;
    display:block;
}


@media screen and (min-width: 0) and (max-width: 830px) {
    #ct_product{
        height:auto;

        .CTProductHeader {
            width: calc(~"100% - 40px");
        }

        .CTProductActions {
            padding: 0 0 0 20px;
        }
    }
    #ct_product_docs {
        display: block;

        .v-btn{
            display:inline-block;
        }
    }
    .CTProductDescription {
        margin-top: 40px;
        margin-bottom:40px;
        width:80%;
    }

    #ct_product_details{
        display:block;
        width:100%;
        height:auto;
        position:relative !important;

        .CTProductTitleContainer{
            width:calc(100% - 20px);
        }
        .CTProductColors{
            margin:30px 0 0 0;
            padding-right:20px;
        }
        #ct_product_image{
            width:100%;
            margin-top:120px;
        }
        #ct_product_infos{
            position:relative;
            margin:40px 0 0 20px;
            height:auto;
        }
        .ProductInfosContent{
            height:auto;
            margin-top:0;
            padding-top:120px;
        }
    }
    #ct_product_right_block{
        position:relative !important;
        width:100%;
        margin-left:20px;
        margin-right:20px;
        display:block;
    }
}


/*

#ct_product h2{
    color:@verallia_green;
    font-size:24px;
    padding:0;
    font-weight: normal;
    margin:20px 0 0 20px;
}
#ct_product h3{
    width:100%;
    font-size:16px;
    padding:0;
    font-weight: normal;
    margin:0 0 20px 0;
}
.CTProductOrderIcon{
    height:36px;
    display: table !important;
    vertical-align: middle !important;

    img{
        width:auto;
        height:36px;
        display: table-cell !important;
    }

    span{
        display: table-cell !important;
        color:@verallia_green;
    }
}
.CTProductLogos{
    padding:0 20px 0 20px;
    margin:20px 0 20px 0;
    width:100%;
    display:block;
}
.CTProductLogos img{
    width:auto;
    height:50px;
    display:inline-block;
    vertical-align: top;
    margin:0 15px 0 0;
}
.CTProductLogoMedium{
    height:40px !important;
}
.CTProductBlock{
    padding:20px;
    display:inline-block;
    vertical-align: top;
}
#ct_product_image{
    min-height:300px;
    width:100%;
    margin-bottom:40px;
    text-align: center;
}
#ct_product_image img{
    width:120px;
}


#ct_product_first_block{
    width:300px;
}
#ct_product_documents{
    width:100%;
}
#ct_product_documents .v-btn{
    width:100% !important;
}



#ct_product_second_block{
    width:100px;
}
#ct_product_second_block.Big{
    width:260px;
}
.CTProductColorContainer{
    width:100%;
    height:65px;
    display:block;
    margin:0 0 10px 0;
}
.CTProductColorInfos{
    position:relative;
    width:65px;
    height:65px;
    display:inline-block;
    vertical-align: top;
    border:2px solid @light_grey;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor:pointer;
}
.CTProductColorInfos.Selected{
    border:2px solid @verallia_green;
}
.CTProductColorInfos img{
    height:90%;
    width:auto;
    margin: 0;
    padding: 0;
    border: none;
}
.CTProductColorInfosPreview{
    width:100%;
    height:100%;
    position:absolute;
    z-index:1;

    .v-icon{
        color:@verallia_purple !important;
        position:absolute;
        margin-left:10px;
        font-size:19px;
    }
}
.CTProductColorInfosPreview span{
    width: 15px;
    height: 15px;
    border-right: 1px solid @light_grey;
    border-bottom: 1px solid @light_grey;
    position: relative;
    padding: 0;
    display: block;
}
.CTProductCountriesInfos{
    width:145px;
    position:relative;
    height:65px;
    display:inline-block;
    vertical-align: top;
    padding:0 0 0 10px;
}
.CTProductCountriesInfos img{
    display:inline-block;
    vertical-align: top;
    height:20px;
    width:auto;
    margin:0 10px 10px 0;
}



#ct_product_third_block{
    width:300px;
    background-color:none;
    background:none;
}
#ct_product_third_block .v-card{
    width:300px;
}
#ct_product .v-card{
    box-shadow:none !important;
}
#ct_product .v-list {
    background:none;
}
#ct_product .v-list__tile{
    user-select:text;
    -webkit-user-select:text;
}
#ct_product .v-list__tile span{
    display:inline-block;
    vertical-align: middle;
}
#ct_product .v-list__tile span img{
    display:inline-block;
    vertical-align: middle;
}
.CTProductColorPreview{
    vertical-align: middle;
    display:inline-block;
    width:15px;
    height:15px;
    border-radius:7.5px;
    border:1px solid @light_grey;
}
#ct_product_fourth_block{
    padding-left:40px;
}*/