html{
    width:100%;
    height:100%;
    overflow-y: auto !important;
}
body{
    background:rgba(0, 0, 0, 0);
    margin:0;
    padding:0;
    width:100%;
    height:100%;
    background:#ffffff;
    font-family:"HelveticaNeue",Helvetica,Arial,sans-serif;
}



@import "Vars.less";




@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../../fonts/material-design/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Material Icons'),
        local('../../fonts/material-design/MaterialIcons-Regular'),
        url(../../fonts/material-design/MaterialIcons-Regular.woff2) format('woff2'),
        url(../../fonts/material-design/MaterialIcons-Regular.woff) format('woff'),
        url(../../fonts/material-design/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}
@font-face{
    font-family:"YanoneRegular";
    src: url('../../fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family:"YanoneBold";
    src: url('../../fonts/Yanone_Kaffeesatz/YanoneKaffeesatz-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}
@font-face{
    font-family:"PoppinsLight";
    src: url('../../fonts/poppins/Poppins-Light.ttf');
    font-weight: lighter;
    font-style: normal;
}
@font-face{
    font-family:"PoppinsRegular";
    src: url('../../fonts/poppins/Poppins-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face{
    font-family:"PoppinsBold";
    src: url('../../fonts/poppins/Poppins-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}
.PoppinsBold{
    font-family: "PoppinsBold";
    letter-spacing: 1px;
}

#Debug{
    position:fixed;
    top:0;
    right:0;
    z-index: 100;
}

#ct_loader{
    z-index: 10;
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    background-color: @white;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#ct_loader_logo{
    position: relative;
    width: 100%;
    max-width: 500px;
    display: block;
    padding: 20px;
}
#ct_loader_logo img{
    width:100%;
    height:auto;
    display:block;
}
#ct_loader_logo span{
    display: block;
    position: absolute;
    right: 45px;
    top: 70%;
    font-size: 30px;
    color: #5A5A5A;
    font-weight: bold;
}
.CTAnimatedLoader{
    -webkit-animation-name: loaderAnim 5s forwards;
    animation-name: loaderAnim 5s forwards;
    animation: loaderAnim 5s forwards;
}
@-webkit-keyframes loaderAnim{
    0%   {transform: scale(1);}
    100%   {transform: scale(1.4);}
}
@keyframes loaderAnim {
    0%   {transform: scale(1);}
    100%   {transform: scale(1.4);}
}
.CTBoldText{
    font-weight: bold;
}


#app{
    width:100%;
    height:100%;
    display:block !important;
}
#app_container{
    width:100%;
    height:100%;
}
#app_global_overlay{
    top:0;
    left:0;
    position: fixed;
    width:100%;
    height:100%;
    z-index: 100;
}
#ct_container{
    position: absolute;
    height: 100%;
    width: 100%;
}
#ct_content{
    height:100%;
}
.Padding0 {
    padding: 0 !important;
}
.Padding10 {
    padding: 10px !important;
}
.Margin0 {
    margin: 0 !important;
}
.PaddingTop10 {
    padding-top: 10px !important;
}
.PaddingTop15{
    padding-top: 15px !important;
}
.PaddingLeft10 {
    padding-left: 10px !important;
}
.PaddingLeft15 {
    padding-left: 10px !important;
}
.PaddingTop20 {
    padding-top: 20px !important;
}
.MarginTop5 {
    margin-top: 5px !important;
}
.MarginTop10 {
    margin-top: 10px !important;
}
.MarginTop15 {
    margin-top: 15px !important;
}
.MarginTop20 {
    margin-top: 20px !important;
}
.MarginBottom5 {
    margin-bottom: 5px !important;
}
.MarginBottom10 {
    margin-bottom: 10px !important;
}
.MarginBottom15 {
    margin-bottom: 15px !important;
}
.MarginBottom20 {
    margin-bottom: 20px !important;
}
.MarginBottom40 {
    margin-bottom: 40px !important;
}
.MarginRight5 {
    margin-right: 5px !important;
}
.MarginRight10 {
    margin-right: 10px !important;
}
.MarginRight15 {
    margin-right: 15px !important;
}
.MarginRight20 {
    margin-right: 20px !important;
}
.MarginLeft5 {
    margin-left: 5px !important;
}
.MarginLeft10 {
    margin-left: 10px !important;
}
.MarginLeft15 {
    margin-left: 15px !important;
}
.MarginLeft20 {
    margin-left: 20px !important;
}
.Width90{
    width:90% !important;
}
.Width100{
    width:100% !important;
}
.Animated02{
    transition: all 0.2s linear;
}
.Animated03{
    transition: all 0.3s linear;
}
.Animated05{
    transition: all 0.5s linear;
}
.CTGreenButton{
    background-color:@verallia_green !important;
    color:@white !important;
}
.CTGreenText{
    color:@verallia_green !important;
}
.CTGreyText{
    color:@verallia_green_alpha2 !important;
}
.CTBoldText{
    font-weight: bold !important;
}
.CTBigFont{
    font-size:30px !important;
}
.CTWhiteUnderlinedText{
    border-bottom: solid 1px @white;
    display: inline;
    padding-bottom: 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.CTUnderlinedText{
    /*text-decoration: underline !important;*/

    border-bottom: solid 1px @verallia_green;
    display: inline;
    padding-bottom: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.CTGreenButton.v-btn--flat{
    background-color:@verallia_green !important;
    color:@white !important;
}
.CTOverlay{
    position:fixed;
    z-index:5;
    width:100%;
    height:100%;
}
.CTOverlayBG{
   position:absolute;
   background-color:rgba(0, 0, 0, 0.7);
   width:100%;
   height:100%;
}
.CTSmallText{
    font-size:12px;
}