@import "Vars.less";

#ct_burger{

}
#ct_burger_logo{
    margin:20px 0 0  0;
}
#ct_burger_logo img{
    height: 50px;
    width: auto;
    display: block;
    margin: 0 auto 0 auto;
}
.CTBurgerMenuIcon{
    color: @verallia_green !important;
    font-size:40px !important;
}
.CTBurgerFlag{
    width:30px;
    height:auto;
    display:inline-block;
}