@import "Vars.less";

#ct_header_container{
    z-index:4;
    position:fixed;
    width:100%;
}
#ct_sup_header{
    width:100%;
    height:35px;
    background-color:@verallia_green;
    display:block;
}
.SupHeaderButton{
    font-family:"PoppinsRegular";
    font-size:10px;
    letter-spacing: 1px;
    line-height:10px;
    text-transform: uppercase;
    color:@white;
    display:inline-block;
    vertical-align: top;
    color: @white !important;

    &.v-btn{
        background-color:transparent !important;
        position:relative;
        margin:0;
        min-width:40px !important;
        height:35px !important;

        .v-btn__content{
            font-family:"PoppinsRegular";
            font-size:10px;
            letter-spacing: 1px;
            line-height:10px;
            text-transform: uppercase;
            color:@white;
            color: @white !important;
        }
    }
    .v-icon{
        color: @white !important;
        font-size:14px !important;
    }
}
.SupHeaderBlock{
    display:inline-block;
    padding:9px 0 0 0;

    font-family:"PoppinsRegular";
    font-size:10px;
    letter-spacing: 1px;
    line-height:10px;
    text-transform: uppercase;
    color:@white;
    vertical-align: top;

    .v-icon{
        color: @white !important;
        font-size:16px;
    }
}
.SupHeaderRightBlock{
    float:right;
}
.HeaderCountryButton{
    height:18px;
    width:auto;
    display:inline-block;
    margin:0 10px 0 0;
    cursor:pointer;
}
#ct_comparator_button{

}
#ct_header{
    display:block;
    width:100%;
    min-height:93px;
    background-color:@light_grey;
    padding-top:35px;
}
.CTHeaderTitleBlock{
    position:absolute;
    margin:0 0 0 40px;

    &.Selected{
        cursor:pointer !important;
    }
    .v-icon{
        display:inline-block;
        vertical-align: top;
        margin:3px 0 0 10px;
        color: @verallia_green !important;
    }
}
.CTHeaderElement{
    display:flex;
    vertical-align: top;
}
.CTHeaderElementRight{
    margin-left: auto;
}
#ct_header h1{
    margin:0 !important;
    display:inline-block;
    vertical-align: top;
    font-family: "PoppinsLight";
    color: @verallia_green;
    padding:0;
    font-size:18px;
    font-weight: normal;
}
#ct_close_product{
    width:60px;
    height:100%;
}
#ct_close_product .v-btn{
    position:relative;
    margin:0 !important;
    padding:0 !important;
    min-width:60px !important;
    width:60px !important;
    height:50px !important;
}
#ct_close_product .v-icon{
    font-size:40px;
    color:@verallia_green;
}
.CTProductsOrder {
    .v-list__tile__title {
        font-family: "PoppinsRegular";
        font-size: 10px;
        letter-spacing: 1px;
        line-height: 10px;
        text-transform: uppercase;

        .v-icon {
            color: @verallia_green;
            font-size: 16px;
        }
    }
}

.CTProductsOrder.Selected {
    color: @verallia_green  !important;
}

.isMobile{
    #ct_sup_header {
        height: 50px;
    }
    .SupHeaderButton {
        font-size: 14px;
        line-height: 14px;

        &.v-btn {
            height: 50px !important;

            .v-btn__content {
                font-family: "PoppinsRegular";
                font-size: 12px;
            }
        }

        .v-icon {
            color: @white  !important;
            font-size: 18px !important;
        }
    }
    .MobileFiltersON {
        background-color: @white  !important;

        .v-icon {
            color: @verallia_green  !important;
        }
    }
    .SupHeaderButtonMobile {
        font-family:"PoppinsRegular";
        font-size:10px;
        letter-spacing: 1px;
        line-height:10px;
        text-transform: uppercase;
        display:block;
        color: @verallia_green  !important;

        &.v-btn {
            background-color: transparent !important;
            position: relative;
            margin: 0;
            min-width: 40px !important;
            height: 35px !important;

            .v-btn__content {
                font-family: "PoppinsRegular";
                font-size: 10px;
                letter-spacing: 1px;
                line-height: 10px;
                text-transform: uppercase;
                color: @verallia_green  !important;
            }
        }

        .v-icon {
            color: @verallia_green  !important;
            font-size: 14px !important;
        }
    }
    .SupHeaderBlock {
        padding: 16px 0 0 0;
        font-size: 14x;

        .v-icon {
            font-size: 18px;
        }
    }
    #ct_header{
        position: absolute;
        background-color:@light_grey;
        padding-top:10px;
    }
}
