@import "Vars.less";

.application #ct_search .theme--light.v-text-field .v-input__slot:before, .theme--light #ct_search .v-text-field .v-input__slot:before{
    background-color:@white !important;
}
#ct_search {
    width: 200px;
    max-width:80%;
    margin-right: 20px;
    caret-color: @white !important;
}
#ct_search .v-input {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding: 0;
    margin-top:0;


    &.v-text-field .v-input__slot:after{
        display:none;
    }

    input{
        font-family: "PoppinsRegular";
        color: @white;
        font-size:14px;
        letter-spacing: 1px;
        font-weight: normal;
        caret-color: @white !important;
        padding:0 !important;
        text-transform: uppercase;
    }

    .v-icon{
        color:@white !important;
        font-size:16px;

        &.primary--text{
            color:@white !important;
        }
    }
}
#ct_search_container{
    width:200px;
    margin-right:20px;
}
#ct_search_results{
    position:absolute;
    right:20px;
    z-index: 20;
    background-color:@white;
    max-width:80%;
    max-height:380px;
    overflow-y:auto;
    width:500px;
    display:flex;
    flex-direction: column;
    padding:20px;
    border-radius:5px;

    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: pink !important;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: pink !important;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: pink !important;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: pink !important;
    }
}
.CTSearchResultBlock{
    display:block;
    width:100%;
    min-height:50px !important;
    padding:5px 0 10px 0;
    border-bottom:1px solid @light_grey;
    cursor:pointer;
    z-index: 25;
    /*overflow:hidden;*/
}
#ct_search_results .CTSearchResultBlock:first-child{
    padding:0 0 10px 0;
}
#ct_search_results .CTSearchResultBlock:last-child{
    padding:5px 0 0 0;
    border-bottom:none;
}
.CTSearchResultBlockOverlay{
    z-index:1;
    position:absolute;
    background-color:rgba(0, 0, 0, 0);
    width:calc(~"100% - 40px");
    height:50px;
}
.CTSearchResultBlockInfos{
    display:block;
    position:absolute;
    margin-left:70px;

    font-family: "PoppinsRegular";
    font-size:14px;
    letter-spacing: 1px;
    font-weight: normal;
    text-transform: uppercase;

    .CTSmallText{
        color: @verallia_green;
        font-size:10px !important;
        font-family: "PoppinsLight";
    }
}
.CTSearchResultBlockInfos span{
    display:block;
}
.CTSearchResultBlock img{
    display:block;
    height:45px;
    width:auto;
}

.isMobile{
    #ct_search {
        margin-top: 10px;
        margin-left:20px;
        max-width:calc(~"100% - 40px");
        width:calc(~"100% - 40px");

        .v-input {
            input {
                font-size: 16px;
                color: @verallia_green;
                caret-color: @verallia_green  !important;
            }

            .v-icon {
                font-size: 24px;
                color: @verallia_green !important;

                &.primary--text {
                    color: @white  !important;
                }
            }
        }
    }
}