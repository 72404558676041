@import "Vars.less";

#ct_comparator{
    display:flex;
    align-items: center;
    justify-content: center;

    font-family:"PoppinsRegular";
    font-size:12px;
    letter-spacing: 1px;
    line-height:12px;
    text-transform: uppercase;
}
#ct_comparator_container{
    position:absolute;
    z-index:6;
    background-color:@white;
    display:flex;
    flex-direction: column;
    border-radius:5px;
    padding:20px;
    min-width:300px;
}
#ct_comparattor_header{
    width:100%;
    height:40px;
    display:flex;
}
#ct_comparattor_header_buttons_container{
    position:absolute;
    right:20px;
}
#ct_comparattor_header h3{
    margin:0;
    padding:0;
    font-weight:bold;
    display:inline-block;
    vertical-align: top;
}
#ct_comparattor_header .v-btn{
    display:inline-block;
    vertical-align: top;
    margin:0;
    padding:0;
}
#ct_comparator_products{
    width:100%;
    display:flex;
    overflow:hidden;
}
#ct_comparator_products_container{
    overflow-x: auto;
    max-width:900px;
}
#ct_comparator_products_container table{
    display:block;
    max-width:860px;
    border-collapse: collapse;
}
#ct_comparator_products_container table tr{
    border-top:1px solid @light_grey;
}
#ct_comparator_products_container table td{
    padding:5px 0 5px 0;
    min-width:200px;
}
#ct_comparator_products_container table td img{
    max-height:150px;
    width:auto;
}
.CTComparatorValueTitle{
    font-weight: bold;
}
