@dark_grey: #252525;
@light_grey: #EDEDED;
@light_grey_alpha: rgba(237, 237, 237, 0.9);
@light_grey2: #58595b;
@light_grey3: rgb(187, 187, 187);
@light_grey4: #a8a8a8;
@verallia_green: #00a8a7;
@verallia_green_alpha: rgba(0, 168, 168, 0.2);
@verallia_green_alpha2: rgba(0, 168, 168, 0.8);
@verallia_purple: #ae0079;
@vuetify_blue: #1976d2;
@verallia_green_dark: rgba(0, 168, 167, 0.4);
@blue: #2378ff;
@dark_blue: #365586;
@red: #ff4949;
@orange: #ff8c49;
@green: #25ca2d;
@white: #ffffff;
@white_alpha: rgba(255, 255, 255, 0.9);
@black: #000000;